.ub-root {
    font-size: 18px;
    color: white;

    display: flex;

    margin-bottom: 20px;
}

.ub-icon {
    margin: 0 10px;
}

.ub-info-name {
    min-height: 50%;

    font-size: 1.0em;
    text-align: left;
}
.ub-info-role {
    min-height: 50%;

    color: #aeb3b7;
    font-size: .85em;
    text-align: left;
}