.cb-button-group-wrapper {
    padding: 10px 15px;
}

.cb-button-group {
    margin-right: 5px;
}

.cb-button-group:last-child {
    margin-right: 0;
}

.cb-switch {
    vertical-align: middle;
}

.cb-switch-label {
    font-size: 1rem;
}