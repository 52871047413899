.login-block {
    background-color: #e9ecef;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    font-family: sans-serif;
}

.login-form-block {
    width: 350px;

    padding: 15px;
    margin: 4em auto;

    background-color: #f8f9fa;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    border-radius: .3rem;
}

.login-form-header {
    text-align: center;
    margin: 5px 0 15px 0;
}

.login-form-err {
    color: #cc0033;
    background-color: #fce4e4;

    border: 1px solid #fcc2c3;
    border-radius: .3rem;

    margin-bottom: 1rem;
    padding: 0.5rem;
    font-size: 0.8rem;
}