.eb-root {
    font-size: 16px;
    text-align: left;

    min-width: 300px;
    width: 100%;
    padding: 0 15px 15px;

    height: 100%;
    display: flex;
    flex-direction: column;
}

.eb-header {
    padding-top: 0.75em;
    padding-bottom: 0.25em;

    border-bottom: 1px solid #dae0e5;

    position: sticky;
    top: 0;
}

.eb-header h4 {
    padding: 0;
    margin: 0;
}

.eb-layout {
    height: max-content;

    overflow-y: scroll;
    overflow-x: hidden;

    padding-top: 1em;
    box-sizing: border-box;
}

.eb-jumbotron {
    min-width: 100%;
    background-color: #e9ecef;
    display: flex;
    align-items: center
}

.eb-jumbotron > h2 {
    min-width: 100%;
}