.tb-table {
    width: 100%;
    padding: 0 15px 5px;

    font-size: 14px;

    flex: 1;
}

.tb-table .table {
    margin-bottom: 0;
}

.tb-row-group-header {
    font-size: 13px;
    font-weight: bold;
    text-align: left;

    background-color: #e9ecef;
    line-height: 0.25;
}

.tb-row-selected {
    background-color: #ffde73;
}
