.mb-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.mb-root {
    font-size: 20px;
    text-align: left;

    display: flex;
    flex-direction: column;
    height: 100%;
}

.mb-root .mb-item {
    padding: .75rem;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #dee2e6;
    width: 100%;
    flex: 0 1 auto;
}

.mb-root .mb-spacer {
    flex: 1 1 auto;
}

.mb-root .mb-item:last-child {
    border-bottom-width: 0;
}

.mb-root .mb-item:hover:not(.mb-selected) {
    background-color: #dae0e5;
}

.mb-root .mb-selected {
    background-color: #aeb3b7;
}

.mb-header {
    width: 100%;
    flex-shrink: 0;

    background-color: #04396C;
    display: flex;
    flex-direction: column;
}

.mb-title {
    margin: 30px;
    color: white;
    font-size: 36px;
    text-align: center;
}

.mb-notification-item {
    display: flex;
    flex-direction: row;
}
.mb-notification-name {
    flex-grow: 1;
}

.mb-notification-counter {
    flex-grow: 0;
}
