.app-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: 100%;
  min-height: 100%;

  font-family: sans-serif;
}

.app-header {
  padding: 10px;
  width: 100%;

  background-color: #04396C;
  color: white;
}

.split {
  height: 100%;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
}

.app-container .left {
  left: 0;
  width: 300px;
  background-color: #f8f9fa;
}
.app-container .right {
  right: 0;
  width: calc(100% - 300px);
  border-left: 1px solid #dee2e6;
}