.cmp-loading-overlay {
    position: fixed;
    width: inherit;
    height: inherit;
    top: inherit;
    left: inherit;
    right: inherit;
    bottom: inherit;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 9rem;

    color: #aeb3b7;
}

.cmp-loading-overlay-spinner {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border 1.0s linear infinite;
}