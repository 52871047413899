.mainb-hsplit {
    width: 100%;
    height: 50%;
    z-index: 1;
    top: 0;
    overflow-y: scroll;
    display: flex;

    border-bottom: 1px solid #dee2e6;
    text-align: center;
}

.mainb-table-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.ws-sticky-cp {
    position: sticky;
    top: 0;
}

.ws-table {
    overflow-y: scroll;
    box-sizing: border-box;
    width: inherit;
}