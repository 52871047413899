.notification-list-root {
    padding: 15px;
}

.notification-list-header {
    border-bottom: 1px solid #dae0e5;
}

.notification-list-group {
    font-size: 1rem;
}